<template>
  <v-dialog v-model="download.running" width="70vw">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <v-progress-linear v-model="download.percentCompleted" :height="height">
          <template v-slot:default="{ value }">
            <strong :class="true ? 'textWhite' : 'textWhite'"
              >{{ Math.ceil(value) }}%</strong
            >
          </template>
        </v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export const download_initial_state = () => ({
  running: false,
  percentCompleted: 0,
});

export default {
  name: "ProgressBar",
  props: {
    title: {
      type: String,
      default: "Téléchargement...",
    },
    height: {
      type: Number,
      default: 25,
    },
    download: {
      type: Object,
      required: true,
      validator(value) {
        return (
          "running" in value &&
          "percentCompleted" in value &&
          typeof value.running === "boolean" &&
          !isNaN(parseFloat(value.percentCompleted))
        );
      },
    },
  },
  data() {
    return {
      dialogModel: {
        dialog: this.download.running,
      },
    };
  },
};
</script>

<style scoped>
.textBlack {
  color: #000;
}
.textWhite {
  color: #fff;
}
</style>
