var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"align-start"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Recherche (par : Site, Redac, Catégorie, Titre, URL, ...)","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('table-stats',{attrs:{"data":_vm.stats}})],1)],1),_c('dot-menu',{attrs:{"isLoading":_vm.loading,"csvButton":{
        csvData: _vm.csvData,
        filename: ("affiliations-" + (this.startDate) + "_" + (this.endDate) + ".csv"),
        disabled: _vm.exportDisabled,
      },"excelButton":{
        excelData: _vm.excelData,
        filename: ("affiliations-" + (this.startDate) + "_" + (this.endDate) + ".xlsx"),
        disabled: _vm.exportDisabled,
      }},on:{"exportCsv":_vm.exportCSV,"exportExcel":_vm.exportExcel}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"page":_vm.options.page,"pageCount":_vm.numberOfPages,"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"sort-by":'nb_clicks',"sort-desc":true,"search":_vm.search,"footer-props":{
        'items-per-page-options': [10, 25, 50, 100, 200, 500],
      },"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.title}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.url_path_identifier",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.url_path_identifier}},[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.url_path_identifier))])])]}},{key:"item.meta_description",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.meta_description}},[_vm._v(" "+_vm._s(item.meta_description)+" ")])]}},{key:"item.shop_name_details",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.shop_name_details}},[_vm._v(" "+_vm._s(item.shop_name)+" ")])]}}])})],1),_c('progress-bar',{attrs:{"download":_vm.download}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }